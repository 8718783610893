const config = {
  rootProjectId: "root",
  uiBucketName: "mmitest.c.retter.io",
  appUrl: "https://api.mmitest.retter.io/",
  cosUrl: "api.mmitest.retter.io",
  version: "2.1.22",
  captchaKey: "6Ldji3oqAAAAALgROS05vqnyptoAI81sHb9khoXT",
  stage: "PROD"
}
export default config

